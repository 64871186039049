.Header {
    position: relative;
    padding: 50px;
    font-weight: 100;
    top: 50px;
    transition: color 1s ease-in-out;
}

.Header div {
    font-weight: 300;
    font-size: 20px;
    overflow: hidden;
}

.Header p {
    font-weight: 100;
    font-size: 15px;
    overflow: hidden;
}


@media only screen and (max-width: 900px) {
    .Header {
        padding: 10px 0 50px 0;
    }

    .Header div {
        font-size: 18px;
    }

    .Header p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 600px) {
    .Header {
        padding: 10px 0 50px 0;
    }

    .Header div {
        font-size: 15px;
    }

    .Header p {
        font-size: 10px;
    }
}

/** MOBILE **/
@media only screen and (max-width: 425px) {
    .Header {
        padding: 0px 0 30px 0;
    }

    .Header div {
        font-size: 15px;
    }

    .Header p {
        font-size: 10px;
    }
}

