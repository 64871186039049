.Footer a {
    font-weight: 100;
    margin: 20px;
    text-decoration: none;
    transition: color 1s ease-in-out;
    cursor: pointer;
    z-index: 10;
    color: black;
    transition: opacity 1s ease-in-out,
                color 1s ease-in-out;
}

@media only screen and (max-width: 700px) {
    .Footer span {
        font-size: 10px;
    }
}