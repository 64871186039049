.ColorSelector {
    display: flex;
    align-items: center;
    background-color: #1a1a1b;
    width: 35%;
    min-width: 300px;
    height: 100px;
    margin: 20px;
}

.BoxHighlight {
    height: 100px;
    width: 2px;
    transition: background-color 2s ease;
}

.ColorSelector h1 {
    padding-left: 10px;
    font-size: 8px;
    width: 20px;
    font-weight: 300;
    white-space: nowrap;
    transition: background-color 2s ease;
}

.ColorSelector input {
    position: relative;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 40px;
    color: white;
    width: 90%;
    height: 50px;
    bottom: 10px;
    border: hidden;
    transition: background-color 2s ease;
}

.ColorSelector input:focus{
    outline: none;
}

.InputContainer {
    width: 100%;
}

.Button {
    position: relative;
    color: 'white';
    right: 10px;
    top: 5.5px;
    width: 135px;
    height: 52px;
    border: hidden;
    transition: background-color 2s ease;
}