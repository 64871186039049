.PageLink {
    position: relative;
    margin: 5px;
    display: flex;
    flex-direction: row;
    transition: height 1s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    outline : none;
    left: 5%;
}

.PageLink h1 {
    margin: 0;
    font-size: 125px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-text-stroke: transparent;
    stroke: 0;
    transition: color 1s ease, 
                opacity 1s ease, 
                transform 1.5s ease, 
                letter-spacing 1s ease-in-out;
}

.PageLink h6 {
    position: relative;
    height: 25px;
    top: 85.5px;
    font-size: 10px;
    padding-left: 5px;
}

.Arrow {
    position: relative;
    width: 15px;
    height: 15px;
    top: 47px;
    left: 20px;
    background-image: url('../../../assets/icons/diagonal-arrow-svgrepo-com.svg');
    background-repeat: no-repeat;
    background-position: center; 
    transform: scale(3);
    transition: opacity 1s ease;
}



@media only screen and (max-width: 1300px) { /* FIRST BREAK */
    .PageLink {

    }

    .PageLink h1 {
        font-size: 100px;
    }

    .PageLink h6 {
        top: 62px;
        font-size: 10px;
    }

    .Arrow {
        top: 35px;
        transform: scale(2);
    }
}

@media only screen and (max-width: 1100px) {
    .PageLink h1 {
        margin: 0;
        font-size: 80px;
        font-weight: 700;
    }

    .PageLink h6 {
        top: 52px;
        font-size: 8px;
    }

    .Arrow {
        top: 25px;
        transform: scale(1.5);
    }
}

@media only screen and (max-width: 865px) {
    .PageLink {
        left: 5%;
    }

    .PageLink h1 {
        margin: 0;
        font-size: 70px;
        font-weight: 700;
    }

    .PageLink h6 {
        top: 47px;
        font-size: 6px;
    }

    .Arrow {
        top: 20px;
        transform: scale(1.3);
        left: 10px;
    }

}

@media only screen and (max-width: 575px) {
    .PageLink {
        left: 25%;
    }

    .PageLink h1 {
        margin: 0;
        font-size: 45px;
        font-weight: 700;
    }

    .PageLink h6 {
        top: 28px;
        font-size: 6px;
    }

    .Arrow {
        top: 12px;
        transform: scale(1);
        left: 10px;
    }
}

/* CSS TRANSITION GROUP */

.appear {
    opacity: 0;
    transform: translateX(-100vw);

}

.appearActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1.5s ease,
                transform 1s ease,
                color 1s ease;
}

.enter {
    opacity: 0.01;
    font-size: 0px;
}

.enter.enterActive {
    opacity: 1;
    font-size: 125px;
    transition: opacity 1000ms ease,
                font-size 1000ms ease;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0.01;
    transition: opacity 2000ms ease;
}

.exitDone {
    opacity: 1;
    transition: opacity 2000ms ease;
}
