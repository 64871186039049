.Config {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    background-color: #121212;
    color: white;
}

.PageStylrContainer {
    display: flex;
    width: 100%;
}

.ColorSelectorContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    padding: 0 5px;
}


.Config nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
}

.navSelected {
    color: 'blue';
}

.navDeselected {
    opacity: .2;
}

.Config nav h6 {
    padding: 0 20px;
}

.LayoutContainer {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 5px;
    width: 100%;
    height: 750px;
    right: 5%;
}

.LayoutContainer h5 {
    position: relative;
    padding: 0 50px;
    right: 20px;
    bottom: -100px;
}

