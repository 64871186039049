.NavBar {
    position: relative;
    display: flex;
    height: 250px;
    transform: translateX(100px);
    padding: 100px;
    transition: transform 1s ease;
}

.NavBar span {
    font-size: 10em;
    font-weight: 100;
}

.NavDash {
    position: relative;
    width: 6vw;
    height: 2px;
    margin-right: 5vw;
    margin-left: 5vw;
    background-color: #2B2D42;
    margin: auto;
    transition: background-color 1s ease;
}

@media only screen and (max-width: 900px) {
    .NavBar {
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 15px 0 40px 0;
        align-items: center;
        transform: translateX(0px);
    }

    .NavDash {
        width: 75vw;
        min-width: 500px;
    }
}

@media only screen and (max-width: 600px) {
    .NavBar {
        padding: 25px 0 25px 0;
    }

    .NavDash {
        min-width: 265px;
    }
}