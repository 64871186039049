.Button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    height: 75px;
    width: 75px;
    border-radius: 100%;
    margin: 50px 0;
    transition: background-color 1s ease,
                border 1s ease,
                opacity 1s ease;
}

.square {
    position: relative;
    width: 3px;
    height: 3px;
    margin: 0 1px;
    z-index: 1;
    transition: background-color 1s ease,
                opacity 1s ease,
                width 1s ease,
}

.circle {
    position: absolute;
    clip-path: circle(50%);
    z-index: 0;
    transition: width .2s ease,
                height .2s ease;
}

@media only screen and (max-width: 1300px) { /* FIRST BREAK */

}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 865px) {
    .Button {
        top: 20px;
        margin-left: 30px;
        height: 50px;
        width: 50px;
    }

    .square {
        width: 2px;
        height: 2px;
        margin: 0 2px;
    }
}

@media only screen and (max-width: 575px) {

}