.NavItem {
    display: flex;
    text-decoration: none;
    padding: 0 50px;
}

.NavTitle {
    display: flex;
    position: relative;
    height: inherit;
    font-size: 12em;
    font-weight: 600;
    transition: color 1s ease, 
                opacity 1s ease, 
                letter-spacing 1s ease-in-out;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    color: black;

}

.NavItem h6 {
    position: relative;
    font-size: 12px;
    top: 5px;
    left: 20px;
    transition: color 1s ease, 
                opacity 1s ease, 
}

@media only screen and (max-width: 900px) {
    .NavItem {
        height: 125px;
        padding: 15px;
    }

    .NavTitle {
        height: inherit;
        font-size: 7em;
    }

    .NavItem h6 {
        font-size: 10px;
        top: -15px;
    }
}

@media only screen and (max-width: 700px) {

    .NavItem {
        height: 65px;
        padding: 10px;
    }

    .NavTitle {
        font-size: 3em;
        font-weight: 600;
    }

    .NavItem h6 {
        position: relative;
        font-size: 8px;
        top: -10px;
        left: 5px;
    }
}