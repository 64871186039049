.About {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    padding: 50px 0;
    transition: color 1s ease,
                clip-path .05s ease;
    /** may want this 
    align-items: center;
    **/
}

.About h1 {
    position: relative;
    cursor: pointer;
    font-size: 80px;
    text-align: left;
    margin: 3px auto;
    padding-top: 3px;
    line-height: .8;
}

.About h1:nth-of-type(2) {
    bottom: 3px;
    padding: 15px;
}

.About p {
    font-size: 14px;
    font-weight: 300;
    -webkit-text-stroke: 0px transparent;
    text-align: left;
    padding: 50px 0;
    width: 450px;
    transition: color 1s ease-in-out;
}

.LinksWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    left: 50%;
    text-align: left;
}

.LinkContainer {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    right: 330px;
}

.LinkContainer div {
    font-size: 16px;
    text-align: left;
    font-weight: 300;
    padding-right: 20px;
    text-decoration: none;
    -webkit-text-stroke: 0px transparent;
    transition: opacity 1s ease-in-out,
                letter-spacing 1s ease-in-out,
                color 1s ease-in-out,
                font-size 1s ease-in-out;
}

.Arrow {
    position: relative;
    width: 20px;
    height: 20px;
    top: 0px;
    left: -20px;
    pointer-events: none;
    background-image: url('../../../assets/icons/diagonal-arrow-svgrepo-com.svg');
    background-repeat: no-repeat;
    transform: scale(.6);
}

@media only screen and (max-width: 1300px) { /* FIRST BREAK */

}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 865px) {
    .About {
        padding: 130px 0;
    }

    .About h1 {
        font-size: 70px;
    }

    .About h1:nth-of-type(2) {
        top: -4px;
        margin: 0;
    }

    .LinksWrapper {
        left: 0;
        align-items: center;
    }

    .LinkContainer {
        right: 0px;
    }

    .About p {
        text-align: center;
    }

}

@media only screen and (max-width: 575px) {
    .About h1 {
        font-size: 50px;
        padding-top: 3px;
        line-height: .8;
        margin: 0;
    }

    .About h1:nth-of-type(2) {
        top: -1px;
        padding: 10px;
        margin: 0;
    }

    .About p {
        font-size: 12px;
        width: 80%;
    }
}

/* CSS TRANSITION GROUP */

.titleAppear {
    transform: translateY(-100vh);

}

.titleAppearActive {
    animation-name: delay-slide-in;
    animation-duration: 1s;
    animation-timing-function: ease;
}

.infoAppear {
    opacity: 0;
}

.infoAppearActive {
    animation-name: delay-fade-in;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-timing-function: ease-in;
}

@keyframes delay-slide-in {
    0%   {transform: translateY(-100vh) rotate(-20deg) scale(.9);}
    75%  {transform: translateY(0vh) rotate(0deg) scale(.9);}
    100% {transform: translateY(0vh) scale(1);}
}

@keyframes delay-fade-in {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}