.Auth {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
}

.Auth .AuthWrapper {
    margin: 20px auto;
    width: 40%;
    text-align: center;
    box-shadow: 5px 5px 15px black;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 600px) {
    .AuthWrapper {
        width: 500px;
    }
}