.PageNav {
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 175px;
}

.PageNav nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    width: 100%;
    height: 100vh;
    top: 0;
    margin: 10px 0px;
    transition: background-color 1s ease;
}

.PageNav nav h1 {
    position: relative;
    white-space: nowrap;
    bottom: 75px;
    transform: rotate(-90deg);
    transition: color 1s ease-in-out;
}

.PageNav nav p {
    position: relative;
    white-space: nowrap;
    bottom: 75px;
    transform: rotate(-90deg);
    transition: color 1s ease-in-out;
}

@media only screen and (max-width: 1300px) { /* FIRST BREAK */

}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 865px) {
    .PageNav {
        height: 50px;
        z-index: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    
    .PageNav nav {
        flex-direction: row;
        font-size: 10px;
        width: 100%;
        height: 100vh;
        margin: 0px 50px;
    }
    
    .PageNav nav h1 {
        top: 20px;
        transform: rotate(0deg);
    }
    
    .PageNav nav p {
        top: 20px;
        transform: rotate(0deg);
        padding-right: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .PageNav {
        height: 50px;
    }
}

/* CSS TRANSITION GROUP */

.appear {
    transform: translateX(-100vw);
}

.appearActive {
    animation-name: delay-slide-in;
    animation-delay: .5s;
    animation-duration: 1s;
    animation-timing-function: ease;
}

@keyframes delay-slide-in {
    0%   {transform: translateX(-100vw);}
    100% {transform: translateX(0vw);}
}

.appearMobile {
    transform: translateY(-100vh);
}

.appearActiveMobile {
    animation-name: delay-slide-in-mobile;
    animation-delay: .5s;
    animation-duration: 1s;
    animation-timing-function: ease;
}

@keyframes delay-slide-in-mobile {
    0%   {transform: translateY(-100vw);}
    100% {transform: translateX(0vw);}
}