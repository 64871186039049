.NewProJect {
    display: flex;
    align-items: center;
    background-color: #1a1a1b;
    width: 25%;
    min-width: 300px;
    height: 70px;
    margin: 20px auto;
}

.OuterWrapper {
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.InnerWrapper {
    width: 30%;
}

.BoxHighlight {
    height: 100%;
    width: 2px;
    transition: background-color 2s ease;
}

.NewProJect h1 {
    padding-left: 10px;
    margin: 0;
    font-size: 10px;
    width: 20px;
    font-weight: 300;
    white-space: nowrap;
    transition: background-color 2s ease;
}

.NewProJect input {
    position: relative;
    margin: 5px 10px;
    font-size: 40px;
    color: white;
    width: 90%;
    height: 35px;
    border: hidden;
    transition: background-color 2s ease;
}

.NewProJect input:focus {
    outline: none;
}

textarea {
    position: relative;
    margin: 5px 10px;
    font-size: 20px;
    width: 93%;
    height: 280px;
    color: white;
    resize: none;
    border: none;
    outline: none;
}

.Label {
    display: flex;
    justify-content: center;
    align-items:center;
    margin: 5px 10px;
    width: 90%;
    height: 35px;
    bottom: 10px;
    font-size: 12px;
    border: hidden;
    transition: background-color 2s ease;
    cursor: pointer;
}

.InputContainer {
    width: 100%;
}

.ImgContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    top: 20px;
    left: 25px;
    margin: 5px 10px;
}

.ImgContainer img {
    object-fit: cover;
    width: 80%;
    height: 80%;
}

.ImgsContainer {
    display: flex;
    flex-direction: column;
}