.Layout {
    position: absolute;
    width: 100vw;
    height: 100%;
    min-height: 500x;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    transition: background-color 1s ease-in-out,
                clip-path .05s ease;
}
