.Contact {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 100px 0;
    transition: color 1s ease-in-out,
                clip-path .05s ease;
}

.Contact h1 {
    position: relative;
    font-size: 80px;
    padding-top: 3px;
    line-height: .8;
    cursor: pointer;
    margin: 0;
}

.Contact h1:nth-of-type(2) {
    padding: 10px;
    line-height: 70px;
    margin: 0;
}

.Contact h1:nth-of-type(3) {
    right: 158px;
    bottom: 37px;
    padding: 15px;
}

.LinksWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 25px;
}

.LinkContainer {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    width: 80px;
}

.LinkContainer div {
    font-size: 16px;
    text-align: left;
    font-weight: 300;
    padding-right: 20px;
    text-decoration: none;
    -webkit-text-stroke: 0px transparent;
    transition: opacity 1s ease-in-out,
                letter-spacing 1s ease-in-out,
                color 1s ease-in-out,
                font-size 1s ease-in-out;
}

.Arrow {
    position: relative;
    pointer-events: none;
    width: 20px;
    height: 20px;
    top: 0px;
    left: -20px;
    background-image: url('../../../assets/icons/diagonal-arrow-svgrepo-com.svg');
    background-repeat: no-repeat;
    transform: scale(.6);
}

.Contact p {
    position: relative;
    font-size: 15px;
    padding-top: 25px;
    -webkit-text-stroke: 0px transparent;
    width: 350px;
    text-align: left;
    left: 135px;
    transition: color 1s ease-in-out;
}

@media only screen and (max-width: 1300px) { /* FIRST BREAK */

}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 865px) {
    .Contact {
        padding: 130px 0;
    }
}

@media only screen and (max-width: 575px) {
    .Contact h1 {
        font-size: 50px;
        padding-top: 3px;
        line-height: .8;
        margin: 0;
    }
    
    .Contact h1:nth-of-type(2) {
        padding: 0 10px;
        line-height: 1.2;
        margin: 0;
    }

    .Contact p {
        font-size: 12px;
        width: 300px;
        left: 0px;
        text-align: center;
    }
}

/* CSS TRANSITION GROUP */

.titleAppear {
    transform: translateY(-100vh);

}

.titleAppearActive {
    opacity: 1;
    animation-name: delay-slide-in;
    animation-duration: 1s;
    animation-timing-function: ease;
}

.infoAppear {
    opacity: 0;
}

.infoAppearActive {
    animation-name: delay-fade-in;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-timing-function: ease-in;
}

@keyframes delay-slide-in {
    0%   {transform: translateY(-100vh) rotate(-20deg) scale(.9);}
    75%  {transform: translateY(0vh) rotate(0deg) scale(.9);}
    100% {transform: translateY(0vh) scale(1);}
}

@keyframes delay-fade-in {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}